<template>
    <v-main>
        <v-container>
            <v-row class="mt-5">
                <v-col
                    v-for="(cat, i) in categories"
                    cols="12"
                    sm="6"
                    md="4"
                    :key="i"
                >
                    <v-card
                        class="fill-height"
                        rounded="xl"
                    >
                        <v-card-text>
                            <div class="text-center">
                                <v-btn
                                    class="pa-11"
                                    style="cursor: auto"
                                    color="blue lighten-2"
                                    :ripple="false"
                                    icon
                                    outlined
                                >
                                    <v-icon
                                        size="50"
                                        v-text="cat.icon"
                                        outlined
                                    />
                                </v-btn>
                                <div
                                    class="text-h5 font-weight-light"
                                    v-text="cat.name"
                                />
                            </div>
                        </v-card-text>
                            
                        <template v-if="cat.subLinks">
                            <v-divider />
                            <v-card-text class="pt-0">
                                <v-list
                                    rounded
                                    dense
                                >
                                    <v-list-item
                                        v-for="(lnk, i2) in cat.subLinks"
                                        :href="lnk.href"
                                        :target="lnk.target"
                                        :to="lnk.to"
                                        :key="i2"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-text="lnk.name"
                                            />
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </template>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
<script>
import { computed } from '@vue/composition-api';

import userHelper from '@/composables/common/user';

export default {
    setup(props, context) {
        const store = context.root.$store;

        const { isInGroup, user } = userHelper(context);

        const userNav = store.getters['userNav'];

        const hrPortalViews = userNav.hr && userNav.hr.nav_portal_views || [];

        const hrPortalViewCategories = hrPortalViews.map(x => {
            return {
                name: x.name,
                to: {name: 'hr/portal-view', params: {view: x.slug}}
            }
        });

        const categories = computed(() => {
            if (user.value.username) {
                
                let homeCards = [{
                    icon: 'mdi-devices',
                    name: 'Information Technology',
                    subLinks: [{
                        name: 'Submit IT Help Request',
                        href: 'https://jira.surgery.columbia.edu/servicedesk/customer/portal/2',
                        target: 'blank'
                    }, {
                        name: 'Inventory Review',
                        to: { name: 'it/inventory/review' }   
                    }
                    /* {
                        name: 'Supported Devices List',
                        to: 'sdfsdf'
                    }, {
                        name: 'Knowledge Base',
                        to: 'asdfsdf'
                    }*/]
                },
                // {
                //     icon: 'mdi-currency-usd',
                //     name: 'Purchasing',
                //     subLinks: [{
                //         name: 'Submit Purchase Request',
                //         href: 'https://jira.surgery.columbia.edu/servicedesk/customer/portal/3',
                //         target: 'blank'
                //     }]
                // }
            ];
                
                let hrCard = {
                    icon: 'mdi-account-multiple',
                    name: 'Human Resources',
                    subLinks: []
                };

                if (isInGroup('hr-portal')) {
                    hrCard.subLinks.push(...[{
                        name: 'New Hires',
                        to: { name: 'newHires' }
                    }, {
                        name: 'Terminations',
                        to: { name: 'terms' }
                    },
                    ...hrPortalViewCategories]);

                    homeCards.splice(0, 0, hrCard);
                }
                
                let opsCard = {
                    icon: 'mdi-cogs',
                    name: 'Operations',
                    subLinks: []
                };

                if (isInGroup('inventory')) {
                    opsCard.subLinks.push(...[{
                            name: 'Cartography',
                            to: { name: 'cartography' }
                    }]);
                    homeCards.splice(3, 0, opsCard);
                    // homeCards.push({
                    //     icon: 'mdi-cogs',
                    //     name: 'Operations',
                    //     subLinks: [{
                    //         name: 'Cartography',
                    //         to: { name: 'cartography' }
                    //     }]
                    // });
                }

                // if (isInGroup('ops-spo-utility')) {
                //     opsCard.subLinks.push(...[{
                //         name: 'SharePoint Utility',
                //         to: { name: 'spoUtility' }
                //     }]);
                //     if (!homeCards.find(card => card.name == 'Operations')) {
                //         homeCards.splice(3, 0, opsCard);
                //     }
                // }



                if (isInGroup('purchasing')) {
                    homeCards.push({
                        icon: 'mdi-currency-usd',
                        name: 'Purchasing',
                        subLinks: [
                        {
                            name: 'Orders',
                            to: { name: 'purchasingOrders' }
                        },{
                            name: 'IT Purchasing',
                            to: { name: 'purchasingType', params: { type: 'it' } }
                        }]
                    });
                }
                

                homeCards.push({
                    icon: 'mdi-microsoft-sharepoint',
                    name: 'SharePoint',
                    subLinks: [{
                        name: 'Finance',
                        href: 'https://cumccolumbia.sharepoint.com/sites/surg/fin',
                        target: 'blank'
                    }]
                });

                return homeCards;

            } else {
                return [];
            }
        });

        return {
            categories,
            isInGroup,
            user
        };
    }
}
</script>